module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-gatsby-cloud-virtual-4b80a34dbd/4/buildhome/.yarn_cache/gatsby-plugin-gatsby-cloud-npm-4.19.0-2129f5d294-40cdf7dadd.zip/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-material-ui-virtual-19c2263431/4/buildhome/.yarn_cache/gatsby-plugin-material-ui-npm-4.1.0-2938ee1c08-f1d38d2e50.zip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":"src/emotion-cache-props"},
    },{
      plugin: require('../../../../../../buildhome/.yarn_cache/gatsby-plugin-web-font-loader-npm-1.0.4-e6329b179e-7cb4df8ed5.zip/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Cabin","Lato","Cabin:bold","Lato:bold","Playfair Display:600,800","Outfit:400,500","Inter:bold&display=swap"]}},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-crisp-chat-virtual-c0aeff2001/4/buildhome/.yarn_cache/gatsby-plugin-crisp-chat-npm-3.2.2-8bc3bab260-f3f84d251c.zip/node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"b6a729f1-209e-4250-9ca3-f0aba7119112"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-4ab7ee306b/4/buildhome/.yarn_cache/gatsby-plugin-manifest-npm-4.19.0-5c73f87aea-bc47920497.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Flock","short_name":"Flock","start_url":"/","background_color":"#03341D","theme_color":"#03341D","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ea1a54bb378266767718cefaead7aeb8"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-segment-js-virtual-068b8066a7/4/buildhome/.yarn_cache/gatsby-plugin-segment-js-npm-3.7.1-69054e5b34-801e02268b.zip/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"Y0g2LtIcZU0og9toxyl0jQdWFhrFwwih","devKey":"Y0g2LtIcZU0og9toxyl0jQdWFhrFwwih","trackPage":false},
    },{
      plugin: require('../../../.yarn/__virtual__/@sentry-gatsby-virtual-529ea5d36d/4/buildhome/.yarn_cache/@sentry-gatsby-npm-6.19.7-98559ac9b4-bf2192507c.zip/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://f902c4a2b0744c09993a8209ef0b8a19@o925641.ingest.sentry.io/5874766","sampleRate":1,"environment":"production","release":"31082810c","ignoreErrors":["ChunkLoadError","GQL Operation failed: GetHomeDetails","ResizeObserver loop limit exceeded","ResizeObserver loop completed with undelivered notifications.","getParameter is not a function","Failed to initialize WebGL.","Could not load \"places_impl\".","Non-Error exception captured","Non-Error promise rejection captured"]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-dca27ab539/4/buildhome/.yarn_cache/gatsby-plugin-google-tagmanager-npm-3.14.0-2e46f23560-8a41cc2a3c.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-56LDZH6","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-25598fae33/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
